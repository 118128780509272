body {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	line-height: 23px;
}
header {
	font-size: 14px;
	font-weight: bold;
	line-height: 40px;
	height: 45px;
	margin-top:10px;
}
header img {
	width: 40px;
	height: auto;
}
header .menu-button {
	float: right;
	color: #33b4e0;
	font-size: 24px;
}

header .menu-wrap {
    background-color: white;
    padding: 10px 0px;
    overflow: hidden;
    transition: all 0.3s;
    position: fixed;
    width: 100%;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: 999;
}
header .menu-overlay {
    background: rgba(0, 0, 0, 0.4);
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 900;
}
header .menu-toggle {
    display: none;
}
header .menu-toggle:checked + .menu-wrap {
    right: 0;
}
header .menu-toggle:checked ~ .menu-overlay {
    display: block;
}
header .menu-header {
	border-bottom: thin solid #e5e5e5;
	padding: 10px 20px;
}
header .menu-list {
    overflow: hidden;
    text-align: left;
    padding: 0 20px;
}
header .menu-wrap:hover .menu-list {
    overflow-y: auto;
}
header .menu-list ul {
    list-style-type: none;
    padding-left: 20px;
    padding-top: 30px;
}
header .menu-list li {
    display: block;
}
.header menu-list li:first-child {
    border-top: medium none;
}
header .menu-list li a {
    display: block;
    padding: 15px 0px;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;    
    color: #494949;
    text-decoration: none;
}
header .menu-list li a:hover {
    text-decoration: underline;
}
header .menu-list li a.active {
    color: #33b4e0;
}
.registerbutton {
	background-color: #f2154e;
	text-align: center;
	padding: 10px 6px;
	border-radius: 5px;
}
.registerbutton a {
	color: white;
	font-weight: bold;
	font-size: 16px;
	line-height: 23px;
}
header div.registerbutton {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 50px;
}
header div.signinbutton {
	text-align: center;
	padding: 0px;
	margin-top: 20px;
}
header div.signinbutton a {
	color: #f2154e;
	font-weight: 500;
	font-size: 16px;
	line-height: 23px;
}

footer {
	background-color:#4F4F4F; 
	color: white;
	padding-top: 30px;
	padding-bottom: 10px;
	margin-bottom: 40px;
}

footer h3 {
	color: #33B4E0;
	text-align: left;
	font-weight: 700;
	font-size: 	18px;
	line-height: 28px;
	margin: 45px 0 14px 0;
}
footer p {
	margin: 0;
}
footer a, footer a:visited, footer a:hover {
	color: white;
	text-decoration: underline;
}
footer #copy {
	margin-top: 45px;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 45px;
}
#footercta {
	background-color: #f2154e;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}
#footercta a, #footercta a:visited, #footercta a:hover {
	color: white;
	font-weight: bold;
	text-decoration: none;
}
#footercta a:hover {
	text-decoration: underline;
}

h1 {
	font-weight: bold;
	font-size: 34px;
	line-height: 38px;
	margin-top: 30px;
	margin-bottom: 20px;
}
h2 {
	font-weight: bold;
	font-size: 33px;
	line-height: 38px;
}
.bluetext {
	color: #33b4e0 !important;
}
.removeoverflow {
	overflow-x: hidden;
}
.subheaderbackground {
	position: absolute;
	top: 230px;
	right: 0;
	left: 0;
	height: 465px;
	overflow: hidden;
}
.subheaderbackground div {
	background-color: #33b4e0;
	height: 300px;
	width: 100%;
}
.subheaderbackground img {
	width: 100%;
	height: auto;
	position: absolute;
	bottom: 0;
}
.subheader1 div:first-of-type {
	color: white;
	margin-top: 60px;
}
.subheader1 div ul {
	list-style-type: none;
	padding: 0;
}
.subheader2 img {
	width: 120%;
	height: auto;
	margin-left: -10%;
	margin-top: -10%;
}

.poscard1 {
	background-color: #33B4E0;
	height: 800px;
	clip-path: polygon(
		0 0,
		100% 0,
		100% calc(100% - 5vw),
		0 100%
	);
}
.poscard1 h3 {
	font-size: 16px;
	line-height: 23px;
	font-weight: bold;
	color: #126784;
	margin: 38px 0 0;
}
.poscard1 h1 {
	font-size: 34px;
	line-height: 38px;
	font-weight: bold;
	color: white;
	margin: 16px 0 0;
}
.poscard1 p {
	color: white;
	margin: 16px 0 28px;
}
.poscard1 div div:nth-of-type(2) {
	height: 350px;
	background-image: url("../immagini/sito/Group 23.png");
}
.poscard2 {
	overflow-x: hidden;
	background-color: #F7F7F7;
	clip-path: polygon(
		0 0,
		100% 0,
		100% 100%,
		0 calc(100% - 2vw)
	);
	padding-bottom: 40px;
}
.poscard2 p {
	color: #494949;
}
.poscard2 .registerbutton {
	margin-top: 30px;
}
.poscard3 {
	margin-bottom: 40px;
}
.poscard3 h2 {
	margin: 60px 0 0;
}
.poscard3 p {
	color: #494949;
	margin: 24px 0 0;
}
.poscard3 .registerbutton {
	margin: 30px 0;
}
.poscard3 .card {
	text-align: left;
	box-shadow: 0 7px 21px 0 rgba(14,82,105,0.19);
	margin-top: 15px;
	width: 100%;
	padding: 25px;
}
.poscard3 .card img {
	width: 40x;
	height: 40px;
}
.poscard3 .card h2 {
	margin-top:8px;
	font-size: 23px;
	line-height: 28px;
	color: #33B4E0;
}
.poscard3 .card p {
	color: #494949;
}
.poscard4 {
	background-color: #33B4E0;
	clip-path: polygon(
		0 0,
		100% 5vw,
		100% 100%,
		0 100%
	);
	text-align: center;
	padding: 100px 0 60px 0;
	height: 1100px;
}
.poscard4 h2 {
	margin: 0 0 35px 0;
	color: white;
}
.poscard4 p {
	color: white;
	margin: 30px 0 30px 0;
}
.poscard4 .listcontainer {
	height: 440px;
	width: 100%;
	overflow-x: hidden;
	position: relative;
}
.poscard4 .list {
	height: 440px;
	width: 150vh;
	margin: 0 auto;
	transition: margin 700ms;
}
.poscard4 .card {
	border-radius: 6px;
	background-color: #FFFFFF;
	box-shadow: 0 13px 42px 4px rgba(7,46,59,0.36);
	width: 40vh;
	height: 400px;
	float: left;
	margin: 0 2.5vh 0 2.5vh;
	position: relative;
}
.poscard4 .card h2 {
	padding-top: 60px;
	font-size: 30px;
	font-weight: bold;
	line-height: 36px;
	color: #494949;
}
.poscard4 .card p {
	color: #494949;
}
.poscard4 .card .registerbutton {
	margin: 0 15px 0 15px;
	width: auto;
}
.poscard4 .card .registerbutton.reverse {
	background-color: white;
	border: thin solid #F2154E;
	border-radius: 5px;
}
.poscard4 .card .registerbutton.reverse a {
	color: #F2154E;
}
.poscard4 .card.mostrequested .requested {
	background-color: #126784;
	color: white;
	font-size: 13px;
	line-height: 15px;
	font-weight: normal;
	text-align: center;
	padding: 10px 0;
	position: absolute;
	width: 85px;
	height: 53px;
	top:0;
	right:19px;
}

.poscard4 .list {
	margin-left: -38vh;
}

.poscard4 .indicator {
	position: absolute;
	width: 2vh;
	color: white;
	bottom: 0;
}
.poscard4 input {
	display: none;
}
.poscard4 input[name="plantype"]:checked + label {
	background: white;
	color: #33B4E0;
}
.poscard4 input[id="plan1"]:checked ~ div:nth-of-type(2) {
	display: block;
}
.poscard4 input[id="plan1"]:checked ~ div:nth-of-type(3) {
	display: none;
}
.poscard4 input[id="plan2"]:checked ~ div:nth-of-type(2) {
	display: none;
}
.poscard4 input[id="plan2"]:checked ~ div:nth-of-type(3) {
	display: block;
}

.poscard4 .listcontainer input:checked + label {
	color: #126784;
}
.poscard4 #card1:checked ~ div:first-of-type {
	margin-left: 3vh;
}
.poscard4 #card2:checked ~ div:first-of-type {
	margin-left: -40.5vh;
}
.poscard4 #card3:checked ~ div:first-of-type {
	margin-left: -82vh;
}
.poscard4 #card4:checked ~ div:first-of-type {
	margin-left: 3vh;
}
.poscard4 #card5:checked ~ div:first-of-type {
	margin-left: -40.5vh;
}
.poscard4 #card6:checked ~ div:first-of-type {
	margin-left: -82vh;
}
.poscard4 .indicator:nth-of-type(1) {
	left: 22vh;
}
.poscard4 .indicator:nth-of-type(2) {
	left: 26vh;
}
.poscard4 .indicator:nth-of-type(3) {
	left: 30vh;
}
.poscard4 label.plan {
	font-size: 20px;
	line-height: 45px;
	color: white;
	width: 144px;
	height: 46px;
	margin: 0 5px 60px;
	border: 1px solid #FFFFFF;
	border-radius: 6px;
}
.poscardfunc {
	padding: 90px 0 40px;
	text-align: left;
}
.poscardfunc.bluebackground {
	background-color: #EAFAFF;
	clip-path: polygon(
		0 0,
		100% 5vw,
		100% calc(100% - 5vw),
		0 100%
	);
}
.poscardfunc h4 {
	color: #33B4E0;
	font-size: 16px;
	line-height: 30px;
	font-weight: 400;
}
.poscardfunc h2 {
	margin: 6px 0 20px 0;
}
.poscardfunc p {
	margin: 0;
	color: #494949;
}
.poscardfunc img {
	width: 100%;
	height: auto;
	margin-left: 0;
}

.registerbutton.bluebackground {
	background-color: white;
}
.registerbutton.bluebackground a {
	color: #F2154E;
}
.card1 div {
	padding: 0 26px;
	min-height: 253px;
	border-radius: 3px; 
	background-color: #FFFFFF; 
	box-shadow: 0 7px 21px 0 rgba(14,82,105,0.19); 
}

.card1 h3 {
	text-align: center;
	color: #33b4e0;
	font-weight: bold;
	font-size: 50px;
	line-height: 53px;
	margin: 0px 0 15px;
	padding-top: 30px;
}
.card1 h2 {
	font-weight: bold;
	font-size: 22px;
	line-height: 29px;
	margin: 0 0 11px;
}
.card1 p {
	color: #494949;
	font-size: 16px;
	line-height: 23px;
	margin: 0 0 53px;
}
.card1 br {
	display: none;
}
.cards2 {
	height: 600px;
}
.card2_1 p, .cards3 p {
	margin-top: 21px;
	color: #494949;
}
.card2_2 {
	height: 70px;
	vertical-align: middle;
}
.card2_2 div div {
	margin-top: 30px;
}
.card2_2 p {
	margin-top: 5px;
}
.card2_2 div.image {
	text-align: center;
	height: 70px;
}
.card2_2 div.text {
	padding-right: 0px;
}
.cards3 {
	background-color: #F7F7F7;
	margin-top:60px;
}
.cards4 {
	height: 1400px;
	background-color: #33b4e0;
	margin-top: -5vw;
	clip-path: polygon(
		0 0,
		100% 5vw,
		100% calc(100% - 5vw),
		0 100%
	);
}
.card4 {
	height: 460px;
	border-radius: 6px;
	background-color: #FFFFFF;
	box-shadow: 0 7px 26px 0 rgba(14,82,105,0.47);
	padding: 45px 24px;
	margin-top: 80px;
}
.card4_1 {
	text-align: left;
	color: white;
	margin-top: 80px;
}
.card4_1 h2 {
	font-size: 23px;
	line-height: 29px;
	font-weight: 900;
}
.card4_1 a, .card4_1 a:hover, .card4_1 a:visited {
	text-decoration: underline;
	color: white;
}
.card4 h2 {
	text-align: center;
	margin: 0 0 23px 0;
}
.card4 h4 {
	font-size: 20px;
	line-height: 26px;
	color: #494949;
	text-align: center;
	margin: 0 0 9px 0;
}
.card4 h3 {
	font-size: 20px;
	line-height: 27px;
	font-weight: 900;
	text-align: left;
	color: #33B4E0;
	height: 96px;
	vertical-align: middle;
	margin: 0 0 10px 0;
}
.card4 p {
	margin: 0;
	color: #494949;
	text-align: left;
	height: 96px;
	margin-bottom: 32px;
}
.cards4 .right {
	margin-top: 40px;
}
.cards5 {
	height: 1000px;
	background-color: #F7F7F7;
	margin-bottom: -100px;
}
.cards5 h2 {
	font-size: 23px;
	line-height: 29px;
	padding: 50px 0 50px;
}
.cards5 .listcontainer {
	height: 480px;
	width: 100%;
	overflow-x: hidden;
	position: relative;
}
.cards5 .list {
	height: 480px;
	width: 150vh;
	margin: 0 auto;
	transition: margin 700ms;
}
.cards5 .card {
	border-radius: 6px;
	background-color: #FFFFFF;
	box-shadow: 0 7px 21px 0 rgba(14,82,105,0.19);
	width: 40vh;
	height: 440px;
	float: left;
	margin: 0 2.5vh 0 2.5vh;
	position: relative;
	padding: 20px;
}
.cards5 .card p {
	color: #494949;
	font-style: italic;
}
.cards5 .card img {
	width: auto;
	height: 60px;
	margin-bottom: 20px;
}
.cards5 .card h4 {
	position: absolute;
	bottom: 10px;
	font-size: 16px;
	line-height: 23px;
	font-weight: bold;
	text-align: left;
}
.cards5 .card h4 a, .cards5 .card h4 a:visited {
	color: black;
	text-decoration: none;
}
 .cards5 .card h4 a:hover {
	 text-decoration: underline;
 }

.cards5 .list {
	margin-left: -38vh;
}

.cards5 .indicator {
	position: absolute;
	width: 2vh;
	color: #E5E5E5;
	bottom: 0;
}
.cards5 input {
	display: none;
}
.cards5 .listcontainer input:checked + label {
	color: #33B4E0;
}
.cards5 #adcard1:checked ~ div:first-of-type {
	margin-left: 3vh;
}
.cards5 #adcard2:checked ~ div:first-of-type {
	margin-left: -40.5vh;
}
.cards5 #adcard3:checked ~ div:first-of-type {
	margin-left: -82vh;
}
.cards5 #adcard4:checked ~ div:first-of-type {
	margin-left: 3vh;
}
.cards5 #adcard5:checked ~ div:first-of-type {
	margin-left: -40.5vh;
}
.cards5 #adcard6:checked ~ div:first-of-type {
	margin-left: -82vh;
}
.cards5 .indicator:nth-of-type(1) {
	left: 22vh;
}
.cards5 .indicator:nth-of-type(2) {
	left: 26vh;
}
.cards5 .indicator:nth-of-type(3) {
	left: 30vh;
}
.cards5 label.plan {
	font-size: 20px;
	line-height: 45px;
	color: white;
	width: 144px;
	height: 46px;
	margin: 0 5px 60px;
	border: 1px solid #FFFFFF;
	border-radius: 6px;
}
.cards1.pos {
	margin-top: -5vw;
	background-color: #F7F7F7;
}
.cards6 {
	height: 455px;
	padding: 55px 0;
	background: url(../immagini/sito/carousel1.jpg) rgba(20, 138, 177, 0.4);
	background-size: cover;
	background-position-x: 50%;
	background-blend-mode: multiply;
	clip-path: polygon(
		0 5vw,
		100% 0,
		100% 100%,
		0 calc(100% - 5vw)
	);
	text-align: center;
}
.cards6 h2 {
	color: white;
	margin: 60px 0 23px;
}
.cards7 {
	margin-top: 60px;
}
.cards7 p {
	color: #494949;
	margin: 12px 0;
}
.cards7 .card {
	text-align: center;
	box-shadow: 0 7px 21px 0 rgba(14,82,105,0.19);
	height: 158px;
	margin-top: 20px;
	width: 48%;
}
.cards7 .card img {
	width: 44px;
	height: 44px;
}
.cards7 .card h2 {
	color: #33B4E0;
}
.cards7 .card p {
	color: #494949;
}
.cards7 .card.right {
	margin-top: 40px;
	float: right;
}
.cards8 {
	background-color: #eafaff;
	clip-path: polygon(
		0 0,
		100% 2vw,
		100% calc(100% - 2vw),
		0 100%
	);
	padding-bottom: 65px;
	margin-top: 40px;
}
.cards8 h2 {
	text-align: center;
	margin: 105px 0 40px;
}
.cards9 {
	padding-bottom: 105px;
}
.cards9 h2 {
	text-align: center;
	margin: 70px 0 30px;
}
.cards10 {
	background-color: #F7F7F7;
	padding-bottom: 90px;
}
.cards10 h2 {
	font-size: 20px;
	line-height: 27px;
	color: #494949;
	margin-top: 90px;
}
.cards10 p {
	color: #494949;
}
.cards10 .privacy, .cards10 .privacy:visited, .cards10 .privacy:hover {
	color: #494949;
}

button[type="submit"] {
	color: #F2154E;
	font-size: 16px;
	padding: 6px 16px;
	border: thin solid #F2154E;
	background-color: transparent;
	border-radius: 3px;
}
.faq {
	margin: 10px 0;
	display: flex;
}
.faq .toggle {
	font-size: 18px;
	font-weight: bold;
	line-height: 20px;
	color: #33B4E0;
	width: 25px;
	height: 25px;
	border: 3px solid #33B4E0;
	border-radius: 12px;
	text-align: center;
	user-select: none;
}
.faq .toggle label {
	width: 25px;
}
.faq .qa {
	padding-left: 18px;
}
.faq .qa h4 {
	font-size: 20px;
	line-height: 27px;
	font-weight: bold;
	margin: 0 0 8px 0;
	color: #494949;
}
.faq .qa .answer {
	color: #494949;
}
.faq input {
    display: none;
}
.faq .answer {
	display: none;
}
.faq .toggle label .plus {
	display: block;
}
.faq .toggle label .minus {
	display: none;
}

.faq input:checked ~ .qa .answer {
    display: block;
}
.faq input:checked ~ .toggle label .plus {
    display: none;
}
.faq input:checked ~ .toggle label .minus {
    display: block;
}
.registration {
	text-align: center;
	margin: 50px 0;
	padding-bottom: 50px;
	position: relative;
}
.registration .backimage {
	position: absolute;
	left: 0;
	right: 0;
	top: 500px;
	height: 900px;
	background-color: #33B4E0;
	overflow-x: hidden;
}
.registration .backimage img {
	position: absolute;
	bottom: 0;
}
.registration h2 {
	margin: 32px 0 20px 0;
}
.registration p {
	color: #494949;
	margin: 0 0 46px;
}
.registration h3 {
	font-size: 23px;
	line-height: 29px;
	margin: 80px 0 30px 0;
}
.registration .form {
	border-radius: 6px; 
	background-color: #FFFFFF; 
	box-shadow: 0 7px 21px 0 rgba(14,82,105,0.19); 
	font-size: 16px;
	line-height: 23px;
	font-weight: normal;
	color: #494949;
	text-align: left;
}
.registration .form h3 {
	margin: 60px 0 15px 0;
}

.registration form label {
	text-align: left;
	font-weight: normal;
}
.registration .radio {
	margin: 8px 0;
}
form [type="radio"]:checked, form [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
form [type="radio"]:checked + label, form [type="radio"]:not(:checked) + label {
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	display: inline-block;
}
form [type="radio"]:checked + label:before, form [type="radio"]:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: -2px;
	width: 28px;
	height: 28px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
}
form [type="radio"]:checked + label:after, form [type="radio"]:not(:checked) + label:after {
	content: '';
	width: 16px;
	height: 16px;
	background: #33B4E0;
	position: absolute;
	top: 4px;
	left: 6px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
form [type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
form [type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

form [type="checkbox"]:checked, form [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
form [type="checkbox"]:checked ~ label, form [type="checkbox"]:not(:checked) ~ label {
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	display: inline-block;
}
form [type="checkbox"]:checked ~ label:before, form [type="checkbox"]:not(:checked) ~ label:before {
	content: '';
	position: absolute;
	left: 0;
	top: -2px;
	width: 28px;
	height: 28px;
	border: 1px solid #CDCDCD; 
	border-radius: 4px; 
}
form [type="checkbox"]:checked ~ label:before {
	background-color: #33B4E0;
}
form [type="checkbox"]:not(:checked) ~ label:before {
	background: white;
}
form [type="checkbox"]:checked ~ label:after, form [type="checkbox"]:not(:checked) ~ label:after {
	content: '';
	width: 6px;
	height: 12px;
	border: solid white;
	border-width: 0 3px 3px 0;
	position: absolute;
	top: 6px;
	left: 11px;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
form [type="checkbox"]:not(:checked) ~ label:after {
	opacity: 0;
	-webkit-transform: rotate(45deg) scale(0);
	transform: rotate(45deg) scale(0);
}
form [type="checkbox"]:checked ~ label:after {
	opacity: 1;
	-webkit-transform: rotate(45deg) scale(1);
	transform: rotate(45deg) scale(1);
}


form [type="checkbox"] + i {
	display: none !important;
}
form [type="text"], [type="email"] {
	height: 44px; 
	border: 1px solid #33B4E0; 
	border-radius: 5px; 
	background-color: #FFFFFF; 
	box-shadow: 0 1px 4px 3px rgba(18,103,132,0.13);
}

form .has-success .form-control {
	border-color: #33b4e0 !important;
}
form .has-success .form-control-feedback {
	color: #33b4e0 !important;
}
form .has-error .form-control {
	border-color: #F2154E !important;
}
form .has-error .form-control-feedback {
	color: #F2154E !important;
}
form .has-success .form-control:focus {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #33b4e0;
}
form .form-group {
	margin-top: 20px;
}
form .help-block {
	text-align: left;
	color: #F2154E !important;
}
.registration form [type="submit"] {
	background-color: #F2154E;
	color: white;
	padding: 14px 25px;
	font-weight: bold;
	border-radius: 5px;
}
.registration a, .registration a:visited, .registration a:hover {
	color: #33b4e0;
	text-decoration: underline;
}
.registration .signinbutton a, .registration .signinbutton a:visited, .registration .signinbutton a:hover {
	color: #F2154E;
	text-decoration: underline;
}
#successform.jumbotron, #failform.jumbotron {
	background-color: #F7F7F7;
}
#successform p, #failform p {
	margin-top: 30px;
	color: #494949;
}
#successform.jumbotron h2 {
	color: #33b4e0;
}
#failform.jumbotron h2 {
	color: #F2154E;
}
#successform a, #successform a:visited, #successform a:hover {
	color: #33b4e0;
	text-decoration: underline;
}
#failform a, #failform a:visited, #failform a:hover {
	color: #F2154E;
	text-decoration: underline;
}
.blogcontainer {
	margin: 10px;
}
.maincontainer {
	margin: 10px;
}
.relatedcontainer {
	margin: 10px;
	display: flex;
	flex-direction: column;
}
.relatedcontainer h3 {
	color: black;
	text-align: left;
}
.relatedcontainer a, .relatedcontainer a:visited, .relatedcontainer a:hover {
	color: #33b4e0;
}
.relatedcontainer a:hover {
	text-decoration: underline;
}
.article {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 30px;
}
.article header {
	height: auto;
	line-height: 150%;
	font-weight: normal;
}
.article a, .article:visited, .article a:hover {
	color: #33b4e0;
}
.article a:hover {
	text-decoration: underline;
}
.article h1 {
	font-size: 24px;
	line-height: 36px;
}
.article p {
	margin-top: 24px;
}
.article img {
	object-fit: cover;
	max-height: 500px;
	width: 100%;
}

.article header p {
	font-style: italic;
}
.article time, .article author {
	font-size: 14px;
}
.article .seeallarticles a, .article .seeallarticles a:visited {
	font-size: 16px;
	color: black;
}

.relatedArticle {
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 25px;
}
.relatedArticle header {
	height: auto;
	line-height: 150%;
	font-weight: normal;
}

.relatedArticle h4 {
	font-size: 22px;
	line-height: 30px;
	font-weight: bold;
}
.relatedArticle p {
	margin-top: 15px;
}
.relatedArticle img {
	object-fit: cover;
	height: 150px;
	width: 100%;
}
.relatedArticle header p {
	font-style: italic;
}
.relatedArticle time, .relatedArticle author {
	font-size: 12px;
}
.relatedArticle .seeallarticles a, .relatedArticle .seeallarticles a:visited {
	font-size: 14px;
	color: black;
}
.sidebar {
	font-size: 16px;
	padding: 10px;
	text-align: center;
	box-shadow: 0px 2px 10px 2px #999999;
	height: min-content;
	margin: 20px;
}
.sidebar div h3 {
	color: black;
	text-transform: uppercase;
}
.sidebar div img {
	height: 100px;
}
.sidebar a, .sidebar a:visited, .sidebar a:hover {
	color: #33B4E0;
}
.sidebar a:hover {
	text-decoration: underline;
}
@media (max-width: 360px) {
	.poscard1 h1 {
		font-size: 26px;
		line-height: 32px;
	}
	.card1 div {
		padding: 0 26px;
		min-height: 280px;
	}
	.poscard4 {
		height: 1200px;
	}
	.poscard4 .container label {
		width: 120px;
	}
	.poscard4 label.plan {
		width: 120px;
	}
	.cards5 .container label {
		width: 120px;
	}
	.cards5 label.plan {
		width: 120px;
	}
	#successform p, #failform p {
		font-size: 18px;
	}
	.registration .backimage {
		height: 1000px;
	}
}
@media (max-width: 480px) {
	h1 {
		font-size: 28px;
		line-height: 34px;
	}
	.registerbutton a {
		font-size: 14px;
	}
	#footercta a {
		font-size: 14px;
	}
	.subheaderbackground {
		top: 210px;
		height: 390px;
	}
	.subheader1 {
		padding-left: 0;
		padding-right: 0;
	}
	.poscard1 {
		height: 650px;
	}
	.poscard3 .card h2 {
		font-size: 26px;
		line-height: 33px;
	}
	.cards2 h2, .cards3 h2 {
		font-weight: bold;
		font-size: 28px;
		line-height: 34px;
	}
	.card2_2 p, .cards3 p {
		font-size: 14px;
		line-height: 18px;
	}
	.card4 h3 {
		height: 72px;
	}
	.card4 {
		height: 500px;
	}
	.card4 p {
		height: 120px;
	}
	.cards5 h2 {
		font-size: 21px;
		line-height: 27px;
	}
	.cards7 .card h2 {
		font-size: 26px;
		line-height: 33px;
	}
	.cards7 .card p {
		font-size: 14px;
	}
}
@media (min-width: 992px) {
	header {
		height: 80px;
	}
	header img {
		width: 72px;
		height: auto;
	}
	.registerbutton {
		padding: 4px 20px;
		line-height: 40px;
		width: 330px;
	}
	.registerbutton.center {
		margin: 0 auto;
	}
	header div.registerbutton, header div.signinbutton, header .menu-list {
		float: right;
		margin-top: 6px;
		padding: 4px 20px;
		width: auto;
	}
	header .menu-list ul { 
		padding: 0px;
	}
	header .menu-list ul li {
		padding: 7px 20px;
		float: left;
	}
	header .menu-list li a {
		font-size: 16px;
		line-height: 23px;
		padding: 0px;
	}
	footer {
		padding-top: 90px;
		margin-bottom: 0;
	}
	footer h3 {
		margin: 0 0 14px 0;
		text-transform: uppercase;
	}
	footer #copy {
		text-align: center;
		margin-bottom: 10px;
	}
	#footercta {
		display:none;
	}
	h1 {
		font-size: 57px;
		line-height: 60px;
		margin-top: 150px;
	}
	h2 {
		font-size: 40px;
		line-height: 46px;
		font-weight: bold;
	}
	.subheaderbackground {
		top: 90px;
		right: 0;
		left: 65%;
		height: 560px;
	}
	.subheader1 div:first-of-type {
		background-color: white;
		color: #494949;
		font-size: 18px;
		line-height: 28px;
		margin-top:16px;
	}
	.subheader1 div.registerbutton {
		max-width: 330px;
	}
	.subheader1 div ul {
		margin-top: 22px;
		margin-bottom: 32px;
	}
	.subheader2 img {
		width: 300%;
		height: auto;
		margin-left: -50%;
		margin-top: 30%;
	}
	.poscard1 {
		height: 730px;
	}
	.poscard1 div div:nth-of-type(2) {
		height: 730px;
		background-position-x: 200%;
	}
	.poscard1 img {
		position: absolute;
		bottom: 0;
	}
	.poscard1 h3 {
		margin: 135px 0 0;
	}
	.poscard1 h1 {
		font-size: 57px;
		line-height: 60px;
	}
	.poscard1 p {
		font-size: 18px;
		line-height: 28px;
	}
	.poscard2 {
		height: 640px;
	}
	.poscard2 .fading {
		background: linear-gradient(to bottom,  rgba(247, 247, 247,0) 0%,rgba(247,247,247,0.8) 100%);
		position: absolute;
		left: 0;
		right: 0;
		top: 200px;
		bottom: 0;
	}
	.poscard2 h4 {
		font-weight: normal;
		font-size: 30px;
		margin-top: 200px;
	}
	.poscard2 ul {
		list-style-type: none;
		width: 100%;
		padding: 0;
	}
	.poscard2 li {
		width: 100%;
		border-top: thin solid #979797;
		color: #494949;
		padding: 16px 0;
	}
	.poscard3 h2 {
		font-size: 47px;
		line-height: 51px;
	}
	.poscard3 .registerbutton {
		margin-top: 36px;
	}
	.poscard3 .card {
		width: 48%;
	}
	.poscard3 .card.right {
		margin-top: 40px;
		float: right;
	}	
	.poscard3 .poscard3_2 {
		margin: 0 auto;
	}
	.poscard4 {
		height: 1100px;
	}
	.poscard4 .listcontainer {
		position: relative;
		margin: auto;
		height: auto;
		overflow: unset;
	}
	.poscard4 .list {
		display: flex;
		justify-content: space-between;
		margin-bottom: 50px;
		position: relative;
		height: auto;
		overflow: unset;
		width: 100vh;
		margin-left: auto;
		margin-right: auto;
	}
	.poscard4 #card1:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.poscard4 #card2:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.poscard4 #card3:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.poscard4 #card4:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.poscard4 #card5:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.poscard4 #card6:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.poscard4 .card {
		width: 30%;
		max-width: 360px;
		height: 430px;
		position: relative;
		margin: auto;
	}
	.poscard4 .card h2 {
		font-size: 40px;
		line-height: 53px;
	}
	.poscard4 .card p {
		font-size: 18px;
	}
	.poscard4 h2 {
		font-size: 57px;
		line-height: 60px;
	}
	.poscard4 .card.mostrequested {
		height: 470px;
		margin-top: -20px;
		position: relative;
	}
	.poscard4 .card.mostrequested .registerbutton {
		margin-top: 100px;
	}
	.poscard4 .card:nth-of-type(1) {
		left: unset;
	}
	.poscard4 .card:nth-of-type(2) {
	}
	.poscard4 .card:nth-of-type(3) {
		right: unset;
	}
	.poscard4 .indicator {
		display: none;
	}
	.poscard4 label.plan {
		width: 260px;
	}
	.poscardfunc h2 {
		font-size: 42px;
		line-height: 48px;
	}
	.poscardfunc p {
		font-size: 18px;
		line-height: 28px;
	}
	.poscardfunc.push-up img {
		margin-top: -40%; 
		margin-bottom: -20%;
	}

	.cards1 div:first-of-type {
		padding-right: 13px;
	}
	.cards1 div:last-of-type {
		padding-left: 13px;
	}
	.card1 div {
		padding: 0 40px;
		min-height: 295px;
	}
	.card1 h3 {
		padding-top: 39px;
		margin: 0px 0 18px;
	}
	.card1 h2 {
		font-size: 28px;
		line-height: 38px;
	}
	.card1 p {
		margin: 0 0 40px;
	}
	.card1 br {
		display: none;
	}
	.cards2 {
		height: 400px;
	}
	.card2_1 p, .cards3 p {
		font-size: 18px;
		line-height: 28px;
	}
	.card2_2 .row {
		min-height: 220px;
	}
	.cards4 {
		height: 930px;
	}
	.card4 {
		padding: 40px;
		margin-top: 165px;
	}
	.card4 h2 {
		margin: 0 0 28px 0;
	}
	.card4 h4 {
		margin: 0 0 28px 0;
	}
	.card4 h3 {
		font-size: 23px;
		line-height: 29px;
		height: 58px;
		vertical-align: middle;
		margin: 0 0 15px 0;
	}
	.card4 p {
		height: 72px;
		margin-bottom: 65px;
	}
	.cards4 .left {
		margin-right: 15px;
	}
	.cards4 .right {
		margin-left: 15px;
		margin-top: 165px;
	}
	.card4_1 {
		text-align: center;
	}
	.cards5 h2 {
		margin: 60px 0;
		text-align: center;
		font-size: 40px;
		line-height: 46px;
	}
	.cards5 .listcontainer {
		position: relative;
		margin: auto;
		height: auto;
		overflow: unset;
	}
	.cards5 .list {
		display: flex;
		justify-content: space-between;
		margin-bottom: 50px;
		position: relative;
		height: auto;
		overflow: unset;
		width: 100vh;
		margin-left: auto;
		margin-right: auto;
	}
	.cards5 #adcard1:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.cards5 #adcard2:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.cards5 #adcard3:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.cards5 #adcard4:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.cards5 #adcard5:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.cards5 #adcard6:checked ~ div:first-of-type {
		margin-left: auto;
	}
	.cards5 .card {
		width: 30%;
		max-width: 360px;
		height: 430px;
		position: relative;
		margin: auto;
	}
	.cards5 .card h4 {
		font-size: 16px;
		line-height: 23px;
		font-weight: bold;
	}
	.cards5 .card p {
		font-size: 18px;
	}
	.cards5 .card:nth-of-type(1) {
		left: unset;
	}
	.cards5 .card:nth-of-type(2) {
	}
	.cards5 .card:nth-of-type(3) {
		right: unset;
	}
	.cards5 .indicator {
		display: none;
	}
	.cards5 label.plan {
		width: 260px;
	}
	.cards6 {
		height: 530px;
		padding: 160px 0 120px;
		clip-path: polygon(
			0 2vw,
			100% 0,
			100% 100%,
			0 calc(100% - 2vw)
		);
	}
	.cards6 h2 {
		font-size: 50px;
		line-height: 53px;
	}
	.cards7 .card {
		width: 200px;
	}
	.cards7 .card7_2 {
		margin: 0 auto;
	}
	.cards10 h2 {
		font-size: 23px;
		line-height: 29px;
	}
	.poscardfunc h2 {
		font-size: 38px;
		line-height: 44px;
	}
	.poscardfunc img {
		margin-top: -15%;
	}
	.registration {
		text-align: left;
	}
	.registration h2 {
		font-size: 47px;
		line-height: 51px;
	}
	.registration p {
		color: #494949;
		margin: 0 0 46px;
	}
	.registration form [type="submit"] {
		float: right;
		margin-top: 15px;
	}
	.registration .backimage {
		left: 50%;
		right: 0;
		top: -50px;
		height: 500px;
		background-color: transparent;
		overflow-x: hidden;
	}
	.registration .backimage img {
		position: absolute;
		bottom: 0;
		top: 0;
		height: 100%;;
		width: 100%;
	}
	.blogcontainer {
		margin: 50px;
	}
	.maincontainer {
		display: flex;
		flex-direction: row;
		margin: 50px;
	}
	.relatedcontainer {
		margin: 50px;
	}
	.innerrelatedcontainer {
		display: flex;
		flex-direction: row;
	}
	.article {
		font-size: 18px;
		line-height: 28px;
		margin-right: 30px;
		margin-bottom: 10px;
	}
	.article h1 {
		font-size: 30px;
		line-height: 40px;
	}
	.article p {
		margin-top: 24px;
	}
	.article img {
		max-height: 500px;
	}
	.article time, .article author {
		font-size: 14px;
	}
	.article .seeallarticles a, .article .seeallarticles a:visited {
		font-size: 18px;
		color: black;
	}

	.relatedArticle {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 10px;
		padding: 10px;
		max-width: 33%;
	}
	.relatedArticle h4 {
		font-size: 24px;
		line-height: 36px;
	}
	.relatedArticle p {
		margin-top: 20px;
	}
	.relatedArticle img {
		height: 200px;
	}
	.relatedArticle time, .relatedArticle author {
		font-size: 12px;
	}
	.relatedArticle .seeallarticles a, .relatedArticle .seeallarticles a:visited {
		font-size: 16px;
		color: black;
	}

	.sidebar {
		min-width: 240px;
		max-width: 240px;
		font-size: 16px;
		padding: 12px;
		margin: 0;
		position: sticky;
		top: 30px;
	}
	.sidebar div h3 {
		font-size: 20px;
		margin-top: 10px;
	}
}
@media (min-width: 1200px) {
	.subheaderbackground {
		left: 55%;
	}
	.subheader2 img {
		width: 150%;
		height: auto;
		margin-left: -20%;
		margin-top: 20%;
	}
	.cards1 div:first-of-type {
		padding-right: 28px;
	}
	.cards1 div:last-of-type {
		padding-left: 28px;
	}
	.cards2 h2, .cards3 h2 {
		font-weight: bold;
		font-size: 47px;
		line-height: 51px;
	}
	.cards7 .card {
		margin-left: 10%;
	}
	.cards7 .card.right {
		margin-left: 0;
		margin-right: 10%;
	}
	.poscardfunc {
		padding: 150px 0 50px;
	}
	.poscardfunc h2 {
		font-size: 45px;
		line-height: 51px;
	}
	.relatedArticle {
		padding: 15px;
		max-width: 25%;
	}
}
